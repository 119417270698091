import { ROUTE } from '~/constants/routes'
import AuthenticationService from '~/services/AuthenticationService'

export default defineNuxtRouteMiddleware(() => {
  const { isLoggedIn } = AuthenticationService.isLoggedIn()

  if (isLoggedIn) {
    return navigateTo(ROUTE.ROOT)
  }
})
